<template>
  <div>
    <Navbar></Navbar>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <div class="row justify-content-end">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
              <button @click="openAdd" class="btn btn-success"><i class="fa fa-plus"></i> Agregar</button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row div_table">
            <div class="col">
              <DataTable class="table table-striped" :columns="columns" :data="items" :options="options">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Servicio</th>
                    <th>Precio</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
              </DataTable>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL -->
    <div class="modal fade" id="actionModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">{{ title }}</h1>
            <button type="button" @click="closeModal" class="btn-close" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="input-group">
              <input type="text" v-model="name" ref=name @input="validate" maxlength="30" class="form-control icon-left"
                placeholder="Servicio">
              <i class="fa fa-tags fa-lg fa-icon-left"></i>
            </div>
            <div class="input-group mt-3">
              <input type="text" v-model="price" ref=price @input="formatPrice" maxlength="30"
                class="form-control icon-left" placeholder="Precio">
              <i class="fa fa-money fa-lg fa-icon-left"></i>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save" :disabled="btn_submit">Guardar</button>
          </div>
        </div>
      </div>
    </div>
    <LoaderComponent v-if="isLoading" />
  </div>
</template>

<script>
import Navbar from '../utils/Navbar.vue';
import LoaderComponent from '../utils/LoaderComponent.vue';
import Toast from '@/services/SweetAlertService';
import Api from '@/services/apiService.js';
import DataTable from 'datatables.net-vue3';
import $ from 'jquery';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import DataTablesCore from 'datatables.net';
DataTable.use(DataTablesCore);

export default {
  name: 'ServicesComponent',
  components: {
    Navbar,
    LoaderComponent,
    DataTable
  },
  data() {
    return {
      isLoading: true,
      // modal
      btn_submit: false,
      title: '',
      modal: '',
      // form
      id: '',
      name: '',
      price: '',
      // datatable
      items: [],
      columns: [
        {
          width: '25%',
          data: 'id',
          render: function (data, type, row, index) {
            return index.row + 1;
          }
        },
        {
          width: '25%',
          data: 'name'
        },
        {
          width: '25%',
          data: 'price',
          render: function (data) {
            // Formatear el valor con dos decimales
            const formattedPrice = parseFloat(data).toFixed(2);
            // Agregar comas para separar los miles si es un número grande
            const parts = formattedPrice.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // Unir las partes formateadas
            return '$ '+parts.join('.');
          }
        },
        {
          width: '25%',
          data: null,
          render: function (data, type, row) {
            return `
              <button class="edit me-2 btn-dt btn btn-primary" data-id="${row.id}"><i class="fa fa-pencil fa-lg"></i></button>
              <button class="delete me-2 btn-dt btn btn-danger" data-id="${row.id}"><i class="fa fa-trash fa-lg"></i></button>
            `;
          }
        }
      ],
      options: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.6/i18n/es-ES.json',
        },
        responsive: true,
      },
    };
  },
  mounted() {
    this.get();
    const vm = this;
    $(document).on('click', '.edit', function () {
      let id = $(this).data('id');
      vm.editarItem(id);
    });
    $(document).on('click', '.delete', function () {
      let id = $(this).data('id');
      vm.eliminarItem(id);
    });
    this.modal = new bootstrap.Modal(document.getElementById('actionModal'));
  },
  methods: {
    async get() {
      this.isLoading = true;
      try {
        const response = await Api.getData('services', {});
        this.isLoading = false;
        if (response.response) {
          this.items = response.data;
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    openAdd() {
      this.title = 'Agregar servicio';
      this.id = '';
      this.name = '';
      this.price = '';
      this.modal.show();
    },
    save(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (this.name.trim() == "" || this.name.length <= 3) {
        this.$refs.name.focus();
        Toast.toast('Ingrese una servicio válida.', 'error');
      } else {
        if (this.id > 0) {
          this.update();
        } else {
          this.add();
        }
      }
    },
    closeModal() {
      this.modal.hide();
    },
    validate() {
      const regex = /^[^a-zA-Z0-9()áéíóúÁÉÍÓÚ-] +$/;
      if (!regex.test(this.name)) {
        this.name = this.name.replace(/[^a-zA-Z0-9()áéíóúÁÉÍÓÚ-] +/, '');
      }
    },
    formatPrice() {
      // Elimina cualquier coma existente y otros caracteres no deseados
      this.price = this.price.replace(/[^0-9.]/g, '');

      // Divide el valor en partes enteras y decimales
      const parts = this.price.split('.');

      // Formatea la parte entera del número con comas para separar los miles
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Limita el número de decimales a dos
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2); // Limita a dos decimales
      }

      // Une las partes formateadas
      this.price = parts.join('.');
    },
    editarItem(id) {
      let row = this.items.filter(item => item.id == id);
      if (row.length > 0) {
        this.title = 'Editar servicio';
        this.id = id;
        this.name = row[0].name;
        this.price = row[0].price;
        this.formatPrice();
        this.modal.show();
      } else {
        Toast.toast('Registro no encontrado', 'error')
      }
    },
    async eliminarItem(id) {
      this.id = id;
      const result = await this.$swal({
        title: '¿Estás seguro de que deseas eliminar?',
        text: 'Esta acción no se puede deshacer.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        this.delete();
      } else {
        this.id = '';
      }
    },
    async add() {
      this.btn_submit = true;
      try {
        const response = await Api.postData('services-add',
          {
            name: this.name,
            price: this.price,
          });
        this.btn_submit = false;
        if (response.response) {
          this.closeModal();
          this.get();
          setTimeout(() => {
            Toast.toast(response.message, 'success');
          }, 1000);
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        this.btn_submit = false;
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    async update() {
      this.btn_submit = true;
      try {
        const response = await Api.postData('services-update',
          {
            id: this.id,
            name: this.name,
            price: this.price,
          });
        this.btn_submit = false;
        if (response.response) {
          this.closeModal();
          this.get();
          setTimeout(() => {
            Toast.toast(response.message, 'success');
          }, 1000);
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        this.btn_submit = false;
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    async delete() {
      this.btn_submit = true;
      try {
        const response = await Api.postData('services-delete',
          {
            id: this.id,
          });
        this.btn_submit = false;
        if (response.response) {
          this.closeModal();
          this.get();
          setTimeout(() => {
            Toast.toast(response.message, 'success');
          }, 1000);
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        this.btn_submit = false;
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
  }
}
</script>

<style scoped>
@import 'bootstrap';
@import 'datatables.net-dt';
@import 'datatables.net-bs5';

.card-body {
  background: #fff;
}
</style>