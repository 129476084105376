<template>
  <div>
    <Navbar></Navbar>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <BarChart :data="dataBar" />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <PieChart :data="dataPie" />
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6">
              <LineChart :data="dataLine" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoaderComponent v-if="isLoading" />
  </div>
</template>

<script>
import Navbar from './utils/Navbar.vue';
import LoaderComponent from './utils/LoaderComponent.vue';
import Toast from '@/services/SweetAlertService';
import Api from '@/services/apiService.js';
import BarChart from './utils/charts/BarChart.vue'
import PieChart from './utils/charts/PieChart.vue'
import LineChart from './utils/charts/LineChart.vue'
export default {
  name: 'DashboardComponent',
  components: {
    Navbar,
    LoaderComponent,
    BarChart,
    PieChart,
    LineChart
  },
  data() {
    return {
      isLoading: true,
      dataBar: [],
      dataPie: [],
      dataLine: [],
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      this.isLoading = true;
      try {
        const response = await Api.getData('dashboard', {});
        this.isLoading = false;
        if (response.response) {
          this.dataBar = response.reservasPorMesArray;
          this.dataPie = response.administration;
          this.dataLine = response.reservasPorTipoArray;
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
  }
}
</script>

<style scoped></style>