<template>
  <Pie id="chart-pie" :options="chartOptions" :data="chartData" :style="myStyles" />
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'vue-chartjs';

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  name: 'PieChart',
  components: { Pie },
  props: ['data'],
  computed: {
    myStyles() {
      return {
        position: 'relative'
      }
    }
  },
  data() {
    return {
      chartData: {
        labels: ['Tarifas', 'Servicios', 'Ciudades', 'Aerolineas'],
        datasets: [
          {
            backgroundColor: ['#b3e682', '#3d60d6', '#5d971c', '#012a76'],
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Administración'
          }
        }
      },
    }
  },
  watch: {
    data: {
      handler() {
        this.chartData = {
          labels: ['Tarifas', 'Servicios', 'Ciudades', 'Aerolineas'],
          datasets: [
            {
              backgroundColor: ['#b3e682', '#3d60d6', '#5d971c', '#012a76'],
              data: this.data
            }
          ]
        };
      },
      deep: true,
    },
  },
}

</script>