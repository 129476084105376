<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-8 col-10">
        <div class="card">
          <img src="@/assets/imgs/logo-ecopark.png" class="logo" alt="Logo">
          <div class="card-body">
            <div class="input-group">
              <input type="text" class="form-control icon-left" placeholder="Usuario" v-model="username" maxlength="30"
                @input="validateUsername" @keyup.enter="login" ref="username">
              <i class="fa fa-user fa-lg fa-icon-left"></i>
            </div>
            <div class="input-group mt-3">
              <input class="form-control icon-left icon-right" placeholder="Contraseña" v-model="password" maxlength="30"
                @input="validatePassword" @keyup.enter="login" ref="password"
                :type="passwordVisible ? 'text' : 'password'">
              <i class="fa fa-lock fa-lg fa-icon-left"></i>
              <i @click="togglePasswordVisibility"
                :class="passwordVisible ? 'fa fa-eye fa-lg fa-icon-right' : 'fa fa-eye-slash fa-lg fa-icon-right'"></i>
            </div>
            <button class="btn btn-success mt-4" v-on:click="login()" :disabled="btn_submit">LOGIN</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from '@/services/SweetAlertService';
import Api from '@/services/apiService.js';

export default {
  name: 'LoginComponent',
  data() {
    return {
      username: '',
      password: '',
      passwordVisible: false,
      btn_submit: false,
    };
  },
  mounted() {
    this.btn_submit = false;
  },
  computed: {
    passwordType() {
      return this.passwordVisible ? 'text' : 'password';
    },
  },
  methods: {
    validateUsername() {
      const regex = /^[a-zA-Z0-9-.:_]+$/;

      if (!regex.test(this.username)) {
        this.username = this.username.replace(/[^a-zA-Z0-9-.:_]+/, '');
      }
    },
    validatePassword() {
      const regex = /^[a-zA-Z0-9!#'()*+-.?@_{|}]+$/;

      if (!regex.test(this.username)) {
        this.username = this.username.replace(/[^a-zA-Z0-9!#'()*+-.?@_{|}]+/, '');
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    async login() {
      if (this.username.trim() == "") {
        this.$refs.username.focus();
        Toast.toast('Ingrese un usuario.', 'error');
      } else if (this.password.trim() == "") {
        this.$refs.password.focus();
        Toast.toast('Ingrese una contraseña.', 'error');
      } else {
        this.btn_submit = true;
        try {
          const response = await Api.postLogin('login_admin',
            {
              username: this.username,
              password: this.password,
            });
          this.btn_submit = false;
          if (response.response) {
            this.initJWTUser(response.data, response.token)
          } else {
            Toast.toast(response.message, 'error');
          }
        } catch (error) {
          this.btn_submit = false;
          Toast.toast('Error al iniciar sesión:' + error, 'error');
        }
      }
    },
    initJWTUser(user, token) {
      document.cookie = "token=" + token;
      document.cookie = "user=" + user;
      this.$router.push({ name: 'dashboard' });
    }
  },
}
</script>
<style scoped>
.card {
  /* margin-top: 10%; */
}

.logo {
  width: 10rem;
  align-self: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
</style>
