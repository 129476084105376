<template>
  <div>
    <Navbar></Navbar>
    <div class="container-fluid cf">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h3>Reservas</h3>
            </div>
            <div class="col" style="text-align: right;">
              <button type="button" class="btn btn-dt btn-primary position-relative" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                <i class="fa fa-bell fa-lg"></i>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {{ this.notification.length }}
                  <span class="visually-hidden">unread messages</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row div_table">
            <div class="col">
              <div class="table-responsive">
                <DataTable class="table table-striped" :columns="columns" :data="items" :options="options">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nro</th>
                      <th>Total</th>
                      <th>Usuario</th>
                      <th>Licencia</th>
                      <th>Fecha Reserva</th>
                      <th>Fecha vuelo</th>
                      <th>Llegada al parqueadero</th>
                      <th>Fecha llegada</th>
                      <th>Personas</th>
                      <th>Maletas</th>
                      <th>Necesidades</th>
                      <th>Servicios</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL -->
    <div class="modal fade" id="actionModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">{{ title }}</h1>
            <button type="button" @click="closeModal" class="btn-close" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row div_table">
              <div class="col">
                <div class="table-responsive">
                  <DataTable class="table table-striped" :columns="columns_h" :data="items_h" :options="options">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Total</th>
                        <th>Licencia</th>
                        <th>Fecha Reserva</th>
                        <th>Fecha vuelo</th>
                        <th>Llegada al parqueadero</th>
                        <th>Fecha llegada</th>
                        <th>Personas</th>
                        <th>Maletas</th>
                        <th>Necesidades</th>
                        <th>Servicios</th>
                      </tr>
                    </thead>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">Actualizaciones</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <div data-bs-dismiss="offcanvas" class="d-flex notification" :class="{ 'nactive': item.view === 0 }"
          v-for="item in notification" :key="item.id">
          <div class="toast-body" @click="search(item)">
            Reserva {{ item.reservation.nro }} fue modificada
          </div>
          <button @click="deleteNotification(item.id)" type="button" class="btn-close me-2 m-auto"></button>
        </div>
      </div>
    </div>
    <LoaderComponent v-if="isLoading" />
  </div>
</template>

<script>
import Navbar from '../utils/Navbar.vue';
import LoaderComponent from '../utils/LoaderComponent.vue';
import Toast from '@/services/SweetAlertService';
import Api from '@/services/apiService.js';
import DataTable from 'datatables.net-vue3';
import $ from 'jquery';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import DataTablesCore from 'datatables.net';
// import Buttons from 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
// import pdfmake from 'pdfmake';
DataTable.use(DataTablesCore);
// DataTable.use(Buttons);
// DataTable.Buttons.pdfMake(pdfmake);

export default {
  name: 'AirlineComponent',
  components: {
    Navbar,
    LoaderComponent,
    DataTable
  },
  data() {
    return {
      isLoading: true,
      // modal
      btn_submit: false,
      title: 'Historial de cambios',
      modal: '',
      // form
      id: '',
      name: '',
      // datatable
      items: [],
      items_h: [],
      services: [],
      notification: [],
      columns: [
        {
          width: '10rem',
          data: 'id',
          render: function (data, type, row, index) {
            return index.row + 1;
          }
        },
        {
          data: 'nro'
        },
        {
          data: 'total',
          className: 'nowrap',
          render: function (data, type, row) {
            let totals = '';
            if (row.difference == 0) {
              const formattedPrice = parseFloat(data).toFixed(2);
              const parts = formattedPrice.split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              totals += '$' + parts.join('.');
            } else {
              const formattedPrice = parseFloat(data - row.difference).toFixed(2);
              const parts = formattedPrice.split('.');
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              totals += '<span style="color: #94c126;">$' + parts.join(".") + '</span>';
              const formattedPriceD = parseFloat(row.difference).toFixed(2);
              const partsD = formattedPriceD.split('.');
              partsD[0] = partsD[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              totals += ' <span style="color: #dc3545;">$' + partsD.join(".") + '</span>';
            }
            return totals;
          }
        },
        {
          data: 'user',
          render: function (data) {
            return data.name + " " + data.lastname;
          }
        },
        {
          data: 'license'
        },
        {
          className: 'nowrap',
          data: 'created_at',
          render: function (data) {
            const date = new Date(data);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          }
        },
        {
          className: 'nowrap',
          data: 'flight_date',
          render: function (data, type, row) {
            return data + " " + row.flight_time;
          }
        },
        {
          data: 'arrival_parking',
        },
        {
          className: 'nowrap',
          data: 'arrival_date',
          render: function (data, type, row) {
            return data + " " + row.arrival_time;
          }
        },
        {
          data: 'peoples',
        },
        {
          data: 'suitcases',
        },
        {
          data: 'special_needs',
        },
        {
          className: 'nowrap',
          data: 'service_id',

        },
        {
          className: 'nowrap',
          data: 'status',
          render: function (data) {
            if (data == 0) {
              return '<span class="badge rounded-pill bg-danger">Cancelada</span>';
            } else if (data == 1) {
              return '<span class="badge rounded-pill bg-success">Activa</span>';
            } else if (data == 2) {
              return '<span class="badge rounded-pill bg-primary">Finalizada</span>';
            }
          }
        },
        {
          className: 'nowrap',
          data: null,
          render: function (data, type, row) {
            let btns = `<button class="history me-2 btn-dt-text btn btn-primary" data-id="${row.id}"><i class="fa fa-list fa-lg"></i>Historial</button>`;
            if (row.status == 1) {
              btns += `<button class="delete me-2 btn-dt-text btn btn-success" data-id="${row.id}"><i class="fa fa-check fa-lg"></i>Finializar</button>`;
            }
            return btns;
          }
        }
      ],
      columns_h: [
        {
          width: '10rem',
          data: 'id',
          render: function (data, type, row, index) {
            return index.row + 1;
          }
        },
        {
          data: 'total',
          className: 'nowrap',
          render: function (data) {
            const formattedPrice = parseFloat(data).toFixed(2);
            const parts = formattedPrice.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return '$' + parts.join('.');
          }
        },
        {
          data: 'license'
        },
        {
          className: 'nowrap',
          data: 'created_at',
          render: function (data) {
            const date = new Date(data);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          }
        },
        {
          className: 'nowrap',
          data: 'flight_date',
          render: function (data, type, row) {
            return data + " " + row.flight_time;
          }
        },
        {
          data: 'arrival_parking',
        },
        {
          className: 'nowrap',
          data: 'arrival_date',
          render: function (data, type, row) {
            return data + " " + row.arrival_time;
          }
        },
        {
          data: 'peoples',
        },
        {
          data: 'suitcases',
        },
        {
          data: 'special_needs',
        },
        {
          className: 'nowrap',
          data: 'service_id',

        },
      ],
      options: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.6/i18n/es-ES.json',
        },
        responsive: true,
        dom: 'Bfrtip', // Agrega botones
        //   {
        //     // extend: 'pdfHtml5', // Opción para exportar a PDF
        //     text: 'Exportar a PDF',
        //     title: 'Título del PDF',
        //     filename: 'nombre_del_archivo',
        //   },
        // ],
      },
    };
  },
  mounted() {
    const vm = this;
    this.get();
    this.getNotification();
    setInterval(() => {
      this.getNotification();
    }, 10000);
    $(document).on('click', '.delete', function () {
      let id = $(this).data('id');
      vm.finishItem(id);
    });
    $(document).on('click', '.history', function () {
      let id = $(this).data('id');
      vm.getHistory(id);
    });
    this.modal = new bootstrap.Modal(document.getElementById('actionModal'));
  },
  methods: {
    async get() {
      this.isLoading = true;
      try {
        const response = await Api.getData('get_all_reserations', {});
        this.isLoading = false;
        if (response.response) {
          this.items = response.data;
          this.services = response.services;

          this.items.forEach(item => {
            let list = "<ul>";
            if (item.service_id) {
              let services = item.service_id.split(',');
              services.forEach(service => {
                let name = this.services.filter(serv => serv.id == service);
                if (name.length > 0) {
                  list += "<li>" + name[0].name + "</li>"
                }
              });
            }
            list += "</ul>";
            item.service_id = list;
          });
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    async getNotification() {
      try {
        const response = await Api.getData('notifications', {});
        if (response.response) {
          this.notification = response.data;
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    async viewNotification(id) {
      try {
        const response = await Api.postData('notifications_view', { id: id });
        if (response.response) {
          console.log('bien');
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    async finishItem(id) {
      this.id = id;
      const result = await this.$swal({
        title: '¿Estás seguro de que deseas finalizar esta reserva?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Finalizar',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        this.finish();
      } else {
        this.id = '';
      }
    },
    async deleteNotification(id) {
      this.isLoading = true;
      try {
        const response = await Api.postData('notifications_delete',
          {
            id: id,
          });
        if (response.response) {
          Toast.toast(response.message, 'success');
          this.isLoading = false;
          this.getNotification();
        } else {
          this.isLoading = false;
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        this.isLoading = false;
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    async finish() {
      this.btn_submit = true;
      try {
        const response = await Api.postData('finish-reservation',
          {
            id: this.id,
          });
        this.btn_submit = false;
        if (response.response) {
          this.get();
          setTimeout(() => {
            Toast.toast(response.message, 'success');
          }, 1000);
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        this.btn_submit = false;
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    async getHistory(id) {
      this.isLoading = true;
      try {
        const response = await Api.postData('reserations_history', { id: id });
        this.isLoading = false;
        if (response.response) {
          this.items_h = response.data;
          if (this.items_h.length > 0) {
            this.items_h.forEach(item => {
              let list = "<ul>";
              if (item.service_id) {
                let services = item.service_id.split(',');
                services.forEach(service => {
                  let name = this.services.filter(serv => serv.id == service);
                  if (name.length > 0) {
                    list += "<li>" + name[0].name + "</li>"
                  }
                });
              }
              list += "</ul>";
              item.service_id = list;
              this.modal.show();
            });
          } else {
            Toast.toast('No se encontró historial de esta reserva.', 'error');
          }

        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
    search(item) {
      this.viewNotification(item.id)
      if (item.view === 0) {
        item.view = 1;
      }
      $('.dataTables_filter>label>input').val(item.reservation.nro);
      $('.dataTables_filter>label>input').trigger('input');
    },
    closeModal() {
      this.modal.hide();
    },
  }
}
</script>

<style scoped>
@import 'bootstrap';
@import 'datatables.net-dt';
@import 'datatables.net-bs5';
@import 'datatables.net-buttons-dt';

.card-body {
  background: #fff;
}

.cf {
  margin-top: 6rem !important;
}

.notification {
  border: 1px solid #e9e9e9;
  padding: 1rem;
}

.toast-body {
  cursor: pointer;
}

.nactive {
  background: #e9e9e9;
}

.nowrap {
  white-space: nowrap !important;
}</style>