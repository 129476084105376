<template>
  <Line id="chart-line" :options="chartOptions" :data="chartData" :style="myStyles" />
</template>

<script>
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS, CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend)

export default {
  name: 'LineChart',
  components: { Line },
  props: ['data'],
  computed: {
    myStyles() {
      return {
        height: `400px`,
        width: `100%`,
      }
    }
  },
  data() {
    return {
      chartData: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Reservaciones por tipo'
          }
        }
      },
    }
  },
  watch: {
    data: {
      handler() {
        this.chartData = {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: this.data
        };
      },
      deep: true,
    },
  },
}
</script>