import Swal from 'sweetalert2';

export default {
    toast(message, type) {
        Swal.fire({
            toast: true,
            icon: type,
            title: message,
            position: 'bottom-end',
            showConfirmButton: false, 
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });
    },
};