import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// CSS
import './assets/css/global.css'
// FONT AWESOME
import 'font-awesome/css/font-awesome.css';

// BOOTSTRAP
import 'bootstrap/dist/css/bootstrap.css'; // Importa los estilos de Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import VueSweetAlert from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
 
const app = createApp(App);
app.use(VueSweetAlert);
app.use(router);
app.mount('#app');
