// apiService.js
import axios from 'axios';

const apiClient = axios.create({
    //baseURL: 'http://127.0.0.1:8000/api/',
    baseURL: 'https://api.ecoparkwebapp.com/api/',
    timeout: 100000,
});

export default {

    getCookieElim(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    },
    deleteCookie(name) {
        if (this.getCookieElim(name)) {
            document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
    },

    getCookie(name) {
        const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split("=");
            if (cookieName === name) {
                return decodeURIComponent(cookieValue);
            }
        }
        return null; // Si la cookie no se encuentra
    },

    async makeRequest(endpoint, method, data = null) {
        const token = this.getCookie("token");
        if (token !== null) {
            const headers = {
                'Authorization': 'Bearer ' + token
            };
            const response = await apiClient.request({
                method,
                url: endpoint,
                headers,
                data,
            });
            console.log(data);
            return response.data;
        } else {
            console.log("La cookie no se encontró.");
        }
    },

    async makeRequestLogin(endpoint, method, data = null) {
        const response = await apiClient.request({
            url: endpoint,
            method,
            data,
        });
        return response.data;
    },

    async postLogin(endpoint, data) {
        return this.makeRequestLogin(endpoint, 'post', data);
    },

    async getData(endpoint) {
        try {
            let response = await this.makeRequest(endpoint, 'get');
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                this.deleteCookie('token');
                window.location.href = "/";
            } else {
                console.error("Error en la solicitud:", error);
            } 5
        }
    },

    async postData(endpoint, data) {
        return this.makeRequest(endpoint, 'post', data);
    },

    async putData(endpoint, data) {
        return this.makeRequest(endpoint, 'put', data);
    },

    async deleteData(endpoint, data) {
        return this.makeRequest(endpoint, 'delete', data);
    },

    // Otras funciones para PUT y DELETE
};