<template>
  <div>
    <Navbar></Navbar>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h3>Pagos</h3>
        </div>
        <div class="card-body">
          <div class="row div_table">
            <div class="col">
              <DataTable class="table table-striped" :columns="columns" :data="items" :options="options">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Usuario</th>
                    <th>Licencia</th>
                    <th>Fecha Reserva</th>
                    <th>Total</th>
                  </tr>
                </thead>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoaderComponent v-if="isLoading" />
  </div>
</template>

<script>
import Navbar from '../utils/Navbar.vue';
import LoaderComponent from '../utils/LoaderComponent.vue';
import Toast from '@/services/SweetAlertService';
import Api from '@/services/apiService.js';
import DataTable from 'datatables.net-vue3';
// import $ from 'jquery';
// import bootstrap from 'boot strap/dist/js/bootstrap.bundle.min.js';
import DataTablesCore from 'datatables.net';
DataTable.use(DataTablesCore);

export default {
  name: 'AirlineComponent',
  components: {
    Navbar,
    LoaderComponent,
    DataTable
  },
  data() {
    return {
      isLoading: true,
      // modal
      btn_submit: false,
      title: '',
      modal: '',
      // form
      id: '',
      name: '',
      // datatable
      items: [],
      columns: [
        {
          width: '20%',
          data: 'id',
          render: function (data, type, row, index) {
            return index.row + 1;
          }
        },
        {
          width: '20%',
          data: 'usuario'
        },
        {
          width: '20%',
          data: 'license'
        },
        {
          width: '20%',
          data: 'created_at',
          render: function (data) {
            const date = new Date(data);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          }
        },
        {
          width: '20%',
          data: 'total',
          render: function (data) {
            // Formatear el valor con dos decimales
            const formattedPrice = parseFloat(data).toFixed(2);
            // Agregar comas para separar los miles si es un número grande
            const parts = formattedPrice.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // Unir las partes formateadas
            return '$ ' + parts.join('.');
          }
        },
      ],
      options: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.6/i18n/es-ES.json',
        },
        responsive: true,
      },
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async get() {
      this.isLoading = true;
      try {
        const response = await Api.getData('payments', {});
        this.isLoading = false;
        if (response.response) {
          this.items = response.data;
        } else {
          Toast.toast(response.message, 'error');
        }
      } catch (error) {
        Toast.toast('Ocurrio un error:' + error, 'error');
      }
    },
  }
}
</script>

<style scoped>
@import 'bootstrap';
@import 'datatables.net-dt';
@import 'datatables.net-bs5';

.card-body {
  background: #fff;
}
</style>