export function checkTokenLogin(to, from, next) {
    const cookieToken = getCookie('token');
    if (cookieToken) {
        next('/dashboard');
    } else {
        next();
    }
}
function getCookie(name) {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Si la cookie no se encuentra
  }