<template>
  <Bar id="chart-bar" ref="bar" :data="chartData" :style="myStyles" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: ['data'],
  computed: {
    myStyles() {
      return {
        height: `400px`,
        width: `100%`,
      }
    }
  },
  data() {
    return {
      chartData: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [
          {
            label: 'Reservaciones',
            backgroundColor: ['#c12626', '#ffc107', '#ff5722', '#e91e63', '#9c27b0', '#673ab7', '#00bcd4', '#4caf50', '#cddc39', '#f5f5f5', '#333333', '#000000'],
            borderWidth: 1,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    }
  },
  watch: {
    data: {
      handler() {
        this.chartData = {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              label: 'Reservaciones',
              backgroundColor: ['#c12626', '#ffc107', '#ff5722', '#e91e63', '#9c27b0', '#673ab7', '#00bcd4', '#4caf50', '#cddc39', '#f5f5f5', '#333333', '#000000'],
              borderWidth: 1,
              data: this.data,
            },
          ],
        };
      },
      deep: true,
    },
  },
}
</script>